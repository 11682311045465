import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Blog from './pages/blog';
import BlogDetail from './pages/BlogDetail';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetailWrapper />} />
        <Route path="/blogs/:id" element={<BlogDetailWrapper />} />
      </Routes>
    </Router>
  );
}

// Wrapper component to handle dynamic imports in BlogDetail
const BlogDetailWrapper = () => {
  const { id } = useParams();
  return <BlogDetail id={id} />;
};

export default App;