import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Container } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import data from '../data/blogs';

const ProjectList = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    AOS.init({
      duration: 1500, // Animation duration in milliseconds
    });
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredProjects = data
    .filter((project) => project.showAsProject === true)
    .filter((project) => project.title.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <TextField
        label="Search Projects"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        sx={{ marginTop: '16px', marginBottom: '32px', width: '100%' }}
        InputProps={{
            style: { color: 'white' },
        }}
        InputLabelProps={{
            style: { color: 'white' },
        }}
      />
      {filteredProjects.map((project, index) => (
        <Box
          key={project.id}
          data-aos={isMobile ? 'fade-up' : index % 2 === 0 ? 'fade-up-right' : 'fade-up-left'}
          data-aos-duration="1500"
          sx={{
            maxWidth: 'lg',
            width: isMobile ? "100%" : "75%",
            padding: '50px',
            margin: '32px',
            background: '#0D0D0D',
            borderRadius: '8px',
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Box sx={{ width: '100%' }}>
          <Typography 
              variant={isMobile ? "h5" : "h3"} 
              component="div" 
              style={{ 
                padding: '7px', 
                wordWrap: 'break-word', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                fontWeight: 'bold'
              }}
            >
              {project.title}
            </Typography>
            <Typography variant={isMobile ? "h7" : "h4"} component="div" style={{ padding: '7px' }}>
              {project.description}
            </Typography>

            <Button component={Link} to={project.url} variant="contained" sx={{ backgroundColor: '#4f46e5' }}>
              GitHub
            </Button> 
            { isMobile ? <br /> : null }
            <Button
              component={Link}
              to={`/blogs/${project.id}`}
              variant="contained"
              sx={{ 
                backgroundColor: '#4f46e5', 
                ...(isMobile ? { marginTop: '15px' } : { marginLeft: '15px' }) 
              }}
            >
              Read More
            </Button>
            <Typography variant={isMobile ? "h8" : "h6"} component="div" style={{ padding: '7px', color: '#9fbdcf' }}>
              {project.date}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const Projects = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <>
      <Container>
        <Typography variant={isMobile ? "h2" : "h1"} component="div" sx={{ textAlign: 'center', marginTop: '64px', fontWeight: '700' }}>
          Projects
        </Typography>
        <ProjectList />
      </Container>
    </>
  );
};

export default Projects;