const data = [
    {
      id: "kitchen1",
      title: 'Estopia Kitchen',
      showAsProject: true,
      description: 'This is a Kitchen Expo React Mobile App with Syncing between Users etc.',
      url: 'https://github.com/kezza2k7/Estopia-Kitchen',
      date: '2024-03-18'
    },
    {
      id: "prot1",
      title: 'Estopia Protect',
      showAsProject: true,
      description: 'This is a Desktop Application made in Rust that transforms a basic USB into a security USB',
      url: 'https://github.com/kezza2k7/Estopia-Protect',
      date: '2024-06-15'
    },
    {
      id: "omgds1",
      title: 'Omegle DS',
      showAsProject: true,
      description: 'This is Discord Bot that brings Omegle to Discord',
      url: 'https://github.com/kezza2k7/Omegle-DS-Bot',
      date: '2024-06-16'
    },
    {
      id: "dev1",
      title: 'Estopia Developments',
      showAsProject: true,
      description: (
        <>
          This is a website made using ReType and hosted at{' '}
          <a href="https://developments.estopia.net" target="_blank" rel="noopener noreferrer">
            Estopia
          </a>
        </>
      ),
      url: 'https://github.com/kezza2k7/estopiadevelopments',
      date: '2024-06-17'
    },
    {
      id: "hadb1",
      title: 'Home Assistant Discord Bot',
      showAsProject: true,
      description: 'This is a Discord Bot made in JavaScript that interacts with Home Assistant, This lets you control your lights, switches etc from Discord',
      url: 'https://github.com/kezza2k7/HomeAssistantDiscordBot',
      date: '2024-06-23'
    },
    {
      id: "mcplug1",
      title: 'Estopia MC Plugin',
      showAsProject: true,
      description: 'This is a Minecraft Plugin made in Java with some basic commands',
      url: 'https://github.com/kezza2k7/EstopiaMinecraftPlugin',
      date: '2024-06-24'
    },
    {
      id: "prjman1",
      title: 'Project Manager',
      showAsProject: true,
      description: 'This is a basic Project manager made in C#',
      url: 'https://github.com/kezza2k7/ProjectManager',
      date: '2024-06-25'
    },
    {
      id: "monopoly1",
      title: 'Monopoly',
      showAsProject: true,
      description: 'This is a Monopoly game made in Java, It is still a work in progress but does support Terminal and Discord based play',
      url: 'https://github.com/kezza2k7/Monopoly',
      date: '2024-06-22'
    },
    {
      id: "restore1",
      title: 'Estopia Restore',
      showAsProject: true,
      description: 'This is a Restore Type Discord Bot using Discord.js',
      url: 'https://github.com/kezza2k7/RestoreEstopia',
      date: '2024-08-02'
    },
    {
      id: "website1",
      title: "Website",
      showAsProject: false,
      description: "This Project aims as a way to showcase my projects and blogs in a more professional way.",
      url: "https://jay.estopia.net",
      date: "2024-08-29"
    },
    {
      id: "hadb2",
      title: 'Home Assistant Discord Bot Upgrade',
      showAsProject: false,
      description: 'This is an upgrade to the Home Assistant Discord Bot. This Project aims to be a way to connect your home assistant to Discord.',
      url: 'https://github.com/kezza2k7/HomeAssistantDiscordBot',
      date: '2024-08-22'
    }
  ];
  
  export default data;
  